import { IconX } from '@tabler/icons-react';
import { useContext, useEffect, useState } from 'react';

import { useCreateReducer } from 'hooks/useCreateReducer';

import { setSavedSetting } from 'utils/app/storage/local/settings';

import { SavedSetting, Setting, SettingsSection } from 'types/settings';

import { SettingsSectionComponent } from './components/SettingsSectionComponent';
import HomeContext from 'components/Home/home.context';

import SettingsContext from './Settings.context';
import { SettingsInitialState, initialState } from './Settings.state';
import ReportsApiService from 'api/reportApi/reportApiService';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Checkbox } from '@mui/material';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { RestrictedContainter } from 'components/Common/RestrictedContainer/RestrictedContainer';
import { ADMIN_ROLE_ID } from 'app/auth/constants';

export const Settings = () => {
  const [isReportBeingGenerated, setIsReportBeingGenerated] = useState(false);
  const [shouldIncludeDbDump, setShouldIncludeDumpDb] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const settingsContextValue = useCreateReducer<SettingsInitialState>({
    initialState
  });

  const {
    state: { searchQuery, selectedSection },
    dispatch: settingsDispatch
  } = settingsContextValue;

  const {
    state: { user, settings },
    dispatch: homeDispatch
  } = useContext(HomeContext);

  useEffect(() => {
    // fetchFiles(searchQuery);
  }, [searchQuery, settingsDispatch]);

  const handleSave = (section: SettingsSection, setting: Setting, value: SavedSetting[]) => {
    const newSavedSettings = setSavedSetting(user!, section.id, setting.id, value);
    homeDispatch({ field: 'savedSettings', value: newSavedSettings });
  };

  const handleSelect = (section: SettingsSection, setting: Setting) => {
    settingsDispatch({
      field: 'selectedSection',
      value: section
    });
    settingsDispatch({
      field: 'selectedSetting',
      value: setting
    });
  };

  const handleClose = () => {
    homeDispatch({ field: 'display', value: 'chat' });
  };

  const onGenerateReport = async () => {
    setIsReportBeingGenerated(true);
    try {
      const start = startDate?.toISOString();
      const end = endDate?.toISOString();
      await ReportsApiService.generateReport({
        startDate: start,
        endDate: end,
        shouldIncludeDbDump
      });
    } catch (e: any) {
      if (e instanceof AxiosError) {
        if (e.response?.status === 401 || e.response?.status === 403) {
          toast.error('You have to be an admin to generate a report');
        }
        if (e.response?.status === 404) {
          toast.error('Invalid date range. Please try again.');
        }
      }
      console.error(e);
    } finally {
      setIsReportBeingGenerated(false);
    }
  };

  return (
    <SettingsContext.Provider
      value={{
        ...settingsContextValue,
        handleSelect,
        handleSave
      }}
    >
      <div className="relative flex-1 bg-theme-light dark:bg-theme-dark">
        <div className="max-h-full overflow-x-hidden"></div>
        <div
          className={`group md:px-4 bg-theme-light text-gray-800
       dark:border-gray-900/50 dark:bg-theme-dark dark:text-gray-100'`}
          style={{ overflowWrap: 'anywhere' }}
        >
          <div className="flex w-full p-4 text-base">
            <div className="w-full">
              <div className="block">
                <h1 className="text-3xl font-bold text-center text-black dark:text-white">
                  Settings
                </h1>
                {settings &&
                  Object.values(settings).map((section, index) => (
                    <SettingsSectionComponent
                      isSelected={selectedSection?.id === section.id}
                      key={index}
                      section={section}
                    />
                  ))}
                <RestrictedContainter roleId={ADMIN_ROLE_ID}>
                  <h1 className="text-3xl font-bold text-center text-black dark:text-white">
                    Reports
                  </h1>
                  <p className="text-black dark:text-white">
                    Generate a report of the current chat history.
                  </p>
                  <div className="mt-2 text-white">
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                  <div className="mt-2 text-white">
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                  <div className="flex flex-row justify-content-center items-center text-white">
                    <Checkbox
                      checked={shouldIncludeDbDump}
                      onChange={(e) => setShouldIncludeDumpDb(e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    Should dump db
                  </div>
                  <div className="flex flex-col">
                    <button
                      className={`w-1/2 p-2 m-2 text-xl font-bold text-white rounded-md ${
                        isReportBeingGenerated ? 'bg-gray-400' : 'bg-blue-500'
                      }`}
                      onClick={() => {
                        onGenerateReport();
                      }}
                      disabled={isReportBeingGenerated}
                    >
                      {isReportBeingGenerated ? 'Generating...' : 'Generate Report'}
                    </button>
                  </div>
                </RestrictedContainter>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          className="
          absolute top-2 right-2 w-6 h-6 m-2 cursor-pointer rounded-sm
          text-gray-700 dark:text-gray-100
          hover:bg-theme-hover-dark dark:hover:bg-theme-hover-dark 
          "
          onClick={handleClose}
        >
          <IconX />
        </button>
      </div>
    </SettingsContext.Provider>
  );
};
