import axiosInstance from 'api/base/baseApi';
import { AiModel } from 'types/ai-models';

const baseUrl = '/Models';

const AIModelApiService = {
  getModelList: (): Promise<AiModel[]> =>
    axiosInstance.get(baseUrl).then((response) => response.data.models)
};

export default AIModelApiService;
