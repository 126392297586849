import { getToken } from 'app/auth/msal';
import { useEffect, useState } from 'react';

interface Props {
  children: React.ReactNode;
  roleId: string;
}

export const RestrictedContainter = ({ children, roleId }: Props) => {
  const [isAllowed, setIsAllowed] = useState(false);
  useEffect(() => {
    const getTokenAndCheckRole = async () => {
      try {
        const token = await getToken();
        const token_parts = token.split('.');
        const token_payload = JSON.parse(window.atob(token_parts[1]));
        const roles = token_payload.roles;
        setIsAllowed(roles.includes(roleId));
      } catch (e) {
        setIsAllowed(false);
        console.error(e);
      }
    };
    getTokenAndCheckRole();
  }, [roleId]);
  return isAllowed ? <>{children}</> : null;
};
