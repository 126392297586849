import { env } from 'app/env';

export const msalConfig = {
  auth: {
    clientId: env.REACT_APP_CLIENT_ID,
    authority: env.REACT_APP_AUTHORITY,
    redirectUri: '/'
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};

export const apiConfig = {
  scopes: [env.REACT_APP_API_SCOPE, 'User.Read', 'User.ReadBasic.All', 'GroupMember.Read.All']
};

export const graphApiConfig = {
  apiEndpoint: 'https://graph.microsoft.com/v1.0',
  scopes: ['User.Read', 'User.ReadBasic.All', 'GroupMember.Read.All']
};

export const ADMIN_ROLE_ID = '6d6cd6f0-d7fe-4b3a-90b7-2dd7fa0727f6';
