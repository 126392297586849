import { getToken } from 'app/auth/msal';
import axios from 'axios';
import { BASE_URL } from './constants';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000, // Set a timeout in milliseconds
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  async (config) => {
    if (config.headers.Authorization !== undefined) {
      return config;
    }
    try {
      const token = await getToken();
      config.headers.Authorization = `Bearer ${token}`;
    } catch (err) {
      console.error(err);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    if (error.response.status === 401) {
    }
    return Promise.reject(error);
  }
);

export function encodeParams(params: Record<string, any>) {
  return Object.entries(params)
    .filter(([key, value]) => value !== null && value !== undefined) // exclude null or undefined values
    .map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value))
    .join('&');
}

export default axiosInstance;
