import { ModelSelect } from './components/ModelSelect';
import { TemperatureSlider } from './components/Temperature';
import { PrimaryLabel } from 'components/Common/Labels/PrimaryLabel';

export const ModelSettings = () => {
  return (
    <div className="pt-2 px-1 space-y-1">
      <PrimaryLabel tip={'The model used for this conversation'}>{'Model'}</PrimaryLabel>
      <ModelSelect />

      {/* <PrimaryLabel tip={'The system prompt to use when sending a message'}>
        {'System Prompt'}
      </PrimaryLabel>
      <SystemPromptSelect /> */}

      <PrimaryLabel
        tip={
          'Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic.'
        }
      >
        {'Temperature'}
      </PrimaryLabel>
      <TemperatureSlider />
    </div>
  );
};
