import { Dispatch, useCallback, useEffect } from 'react';

import { ActionType } from 'hooks/useCreateReducer';

import { AiModel } from 'types/ai-models';

import { HomeInitialState } from 'components/Home/home.state';

import AIModelApiService from 'api/aiModelApi/aiModelApi';
import { User } from 'types/auth';

export const useModels = (
  homeDispatch: Dispatch<ActionType<HomeInitialState>>,
  models: AiModel[],
  user: User | null
) => {
  const fetchModels = useCallback(async () => {
    if (user === null) return;
    if (models.length === 0) {
      const models = await AIModelApiService.getModelList();
      homeDispatch({ field: 'models', value: models });
    }
  }, [homeDispatch, models.length, user]);

  useEffect(() => {
    fetchModels();
  }, [fetchModels]);
};
