import axiosInstance, { encodeParams } from 'api/base/baseApi';
import { saveAs } from 'file-saver';
import { GenerateReportRequest } from './reportApiService.types';

const baseUrl = '/reports';

const ReportsApiService = {
  generateReport: (req: GenerateReportRequest): Promise<void> =>
    axiosInstance
      .get(baseUrl + '/create?' + encodeParams(req), { responseType: 'blob' })
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'default-filename.txt'; // Fallback filename

        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="(.+)"/);
          if (filenameMatch.length === 2) filename = filenameMatch[1];
        }

        var blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        saveAs(blob, filename);
      })
};

export default ReportsApiService;
