import { useCallback, useEffect, useState } from 'react';

import { useCreateReducer } from 'hooks/useCreateReducer';

import {
  DEBUG_MODE,
  DEFAULT_ANTHROPIC_SYSTEM_PROMPT,
  DEFAULT_MODEL,
  DEFAULT_OPENAI_SYSTEM_PROMPT,
  DEFAULT_PALM_SYSTEM_PROMPT,
  DEFAULT_TEMPERATURE
} from 'utils/app/const';
import { useAuth } from 'hooks/retrieval/auth';
import { useConversations } from 'hooks/retrieval/conversations';
import { useDatabase } from 'hooks/retrieval/database';
import { useModels } from 'hooks/retrieval/models';
import { getSavedSettingValue, getSavedSettings } from 'utils/app/storage/local/settings';
import {
  localGetShowPrimaryMenu,
  localGetShowSecondaryMenu
} from 'utils/app/storage/local/uiState';
import { getTimestampWithTimezoneOffset } from 'helpers/time/time';

import { AiModel, PossibleAiModels } from 'types/ai-models';
import { Conversation } from 'types/chat';
import { KeyValuePair } from 'types/data';
import { FolderInterface } from 'types/folder';
import { Prompt } from 'types/prompt';
import { SystemPrompt } from 'types/system-prompt';

import { ChatZone } from './components/ChatZone/ChatZone';
import { Navbar } from './components/Mobile/Navbar';
import { PrimaryMenu } from 'components/Home/components/PrimaryMenu/PrimaryMenu';
import { SecondaryMenu } from 'components/Home/components/SecondaryMenu/SecondaryMenu';

import HomeContext from './home.context';
import { HomeInitialState, initialState } from './home.state';

import { v4 as uuidv4 } from 'uuid';
import { saveSelectedConversation } from 'utils/app/storage/local/selectedConversation';
import ChatApiService from 'api/chatApi/chatApiService';
import { storageUpdateConversation } from 'utils/app/storage/conversation';
import { getSettings } from './components/Settings/getSettings';
import { cleanSelectedConversation } from 'utils/app/clean';

const Home = () => {
  const [debugLogPrinted, setDebugLogPrinted] = useState(false);
  const contextValue = useCreateReducer<HomeInitialState>({
    initialState
  });

  const {
    state: {
      database,
      display,
      lightMode,
      folders,
      conversations,
      selectedConversation,
      prompts,
      systemPrompts,
      user,
      savedSettings,
      settings,
      models,
      builtInSystemPrompts,
      settingsLoaded
    },
    dispatch
  } = contextValue;

  useEffect(() => {
    if (DEBUG_MODE) {
      if (!debugLogPrinted) {
        console.log('----------CLIENT-SIDE ENVIRONMENT VARIABLES----------');
        setDebugLogPrinted(true);
      }
    }
  }, [debugLogPrinted]);

  // AUTH ---------------------------------------------------------
  useAuth(dispatch, user);

  // DATABASE ---------------------------------------------------------
  useDatabase(dispatch, database);

  // MODELS ----------------------------------------------
  useModels(dispatch, models, user);

  // CONVERSATIONS ---------------------------------------------------------
  useConversations(dispatch, database, user, conversations, models);

  const handleSelectConversation = (conversation: Conversation) => {
    if (!database || !user || models.length === 0) return;
    const cleanedConversation = cleanSelectedConversation(conversation, models);
    dispatch({
      field: 'selectedConversation',
      value: cleanedConversation
    });

    dispatch({
      field: 'display',
      value: 'chat'
    });

    saveSelectedConversation(user, cleanedConversation);
  };

  // FOLDER OPERATIONS  --------------------------------------------

  const handleCreateFolder = async (name: string, type: FolderInterface['type']) => {
    if (!database || !user) return;

    const newFolder: FolderInterface = {
      id: uuidv4(),
      name,
      type: type
    };

    const updatedFolders = [...folders, newFolder];

    dispatch({ field: 'folders', value: updatedFolders });
  };

  const handleDeleteFolder = async (folderId: string) => {
    if (!database || !user) return;

    const updatedFolders = folders.filter((f) => f.id !== folderId);
    dispatch({ field: 'folders', value: updatedFolders });

    const updatedConversations: Conversation[] = conversations.map((c) => {
      if (c.folderId === folderId) {
        return {
          ...c,
          folderId: null
        };
      }

      return c;
    });

    dispatch({ field: 'conversations', value: updatedConversations });

    const updatedPrompts: Prompt[] = prompts.map((p) => {
      if (p.folderId === folderId) {
        return {
          ...p,
          folderId: null
        };
      }

      return p;
    });

    dispatch({ field: 'prompts', value: updatedPrompts });

    // await storageUpdateConversations(database, user, updatedConversations);
  };

  const handleUpdateFolder = async (folderId: string, name: string) => {
    if (!database || !user) return;

    const updatedFolders = folders.map((f) => {
      if (f.id === folderId) {
        const updatedFolder = {
          ...f,
          name
        };

        return updatedFolder;
      }

      return f;
    });

    dispatch({ field: 'folders', value: updatedFolders });
  };

  // CONVERSATION OPERATIONS  --------------------------------------------

  const autoUpdateConversations = useCallback(
    async (oldConversations: Conversation[]) => {
      if (!database || !user) return;
      for (const conversation of oldConversations) {
        if (conversation.systemPrompt) {
          const systemPrompt = systemPrompts.find((p) => p.id === conversation.systemPrompt?.id);

          if (systemPrompt) {
            conversation.systemPrompt = systemPrompt;
          } else {
            conversation.systemPrompt = null;
          }
        }
      }

      // storageUpdateConversations(database, user, oldConversations);

      dispatch({ field: 'conversations', value: oldConversations });
    },
    [database, user, systemPrompts, dispatch]
  );

  useEffect(() => {
    if (conversations.length > 0) {
      autoUpdateConversations(conversations);
    }
  }, [autoUpdateConversations, conversations, systemPrompts]);

  const handleNewConversation = useCallback(async () => {
    if (!user) return;
    if (savedSettings && settings) {
      const model = models[0];
      // const sectionId = model.vendor.toLowerCase();
      // const settingId = `${model.id}_default_system_prompt`;
      // const systemPromptId = getSavedSettingValue(
      //   savedSettings,
      //   sectionId,
      //   settingId,
      //   settings,
      // );

      // const systemPrompt = systemPrompts.find((p) => p.id === systemPromptId);

      const newConversation: Conversation = {
        name: 'New Conversation',
        messages: [],
        model: model,
        systemPrompt: null,
        temperature: DEFAULT_TEMPERATURE,
        folderId: null,
        timestamp: getTimestampWithTimezoneOffset()
      };
      const newChatId = await ChatApiService.createChat({
        name: newConversation.name
      });
      newConversation.id = newChatId;
      const updatedConversations = [...conversations, newConversation];
      dispatch({ field: 'selectedConversation', value: newConversation });
      dispatch({ field: 'conversations', value: updatedConversations });

      saveSelectedConversation(user, newConversation);

      dispatch({ field: 'loading', value: false });
    }
  }, [models, conversations, dispatch, user, savedSettings, settings]);

  const generateBuiltInSystemPrompts = useCallback(() => {
    const vendors: AiModel['modelType'][] = ['Anthropic', 'OpenAI', 'Google'];

    const newSystemPrompts: SystemPrompt[] = [];
    for (const vendor of vendors) {
      let systemPrompt: SystemPrompt;
      const systemPromptId = uuidv4();
      if (vendor === 'Anthropic') {
        systemPrompt = {
          id: systemPromptId,
          name: `${vendor} Built-In`,
          content: DEFAULT_ANTHROPIC_SYSTEM_PROMPT,
          folderId: null,
          models: models.filter((m) => m.modelType === 'Anthropic').map((m) => m.id)
        };
        newSystemPrompts.push(systemPrompt);
      } else if (vendor === 'OpenAI') {
        systemPrompt = {
          id: systemPromptId,
          name: `${vendor} Built-In`,
          content: DEFAULT_OPENAI_SYSTEM_PROMPT,
          folderId: null,
          models: models.filter((m) => m.modelType === 'OpenAI').map((m) => m.id)
        };
        newSystemPrompts.push(systemPrompt);
      } else if (vendor === 'Google') {
        systemPrompt = {
          id: systemPromptId,
          name: `${vendor} Built-In`,
          content: DEFAULT_PALM_SYSTEM_PROMPT,
          folderId: null,
          models: models.filter((m) => m.modelType === 'Google').map((m) => m.id)
        };

        newSystemPrompts.push(systemPrompt);
      }
    }

    dispatch({ field: 'builtInSystemPrompts', value: newSystemPrompts });
  }, [dispatch, models]);

  useEffect(() => {
    if (builtInSystemPrompts.length === 0) {
      generateBuiltInSystemPrompts();
    }
  }, [builtInSystemPrompts, generateBuiltInSystemPrompts]);

  const handleUpdateConversation = async (conversation: Conversation, data: KeyValuePair) => {
    const updatedConversation = {
      ...conversation,
      [data.key]: data.value
    };

    let update: {
      single: Conversation;
      all: Conversation[];
    };
    update = await storageUpdateConversation(
      user!,
      updatedConversation,
      conversations,
      data.key !== 'name'
    );
    dispatch({ field: 'selectedConversation', value: update.single });
    dispatch({ field: 'conversations', value: update.all });
    saveSelectedConversation(user!, updatedConversation);
  };

  // EFFECTS  --------------------------------------------

  useEffect(() => {
    if (window.innerWidth < 640) {
      dispatch({ field: 'showPrimaryMenu', value: false });
      dispatch({ field: 'showSecondaryMenu', value: false });
    }
  }, [dispatch, selectedConversation, display]);

  // ON LOAD --------------------------------------------

  useEffect(() => {
    if (!database || !user) return;

    if (window.innerWidth < 640) {
      dispatch({ field: 'showPrimaryMenu', value: false });
    }

    const showPrimaryMenu = localGetShowPrimaryMenu(user);
    if (showPrimaryMenu) {
      dispatch({ field: 'showPrimaryMenu', value: showPrimaryMenu });
    }

    const showSecondaryMenu = localGetShowSecondaryMenu(user);
    if (showSecondaryMenu) {
      dispatch({ field: 'showSecondaryMenu', value: showSecondaryMenu });
    }

    // storageGetFolders(database, user).then((folders) => {
    //   if (folders) {
    //     dispatch({ field: "folders", value: folders });
    //   }
    // });

    // storageGetPrompts(database, user).then((prompts) => {
    //   if (prompts) {
    //     dispatch({ field: "prompts", value: prompts });
    //   }
    // });
  }, [user, database, dispatch]);

  // SETTINGS --------------------------------------------

  useEffect(() => {
    if (!database || !user) return;

    const settings = getSettings();
    dispatch({ field: 'settings', value: settings });

    // storageGetSystemPrompts(database, user).then((systemPrompts) => {
    //   const choices: SettingChoice[] = systemPrompts.map((sp) => {
    //     return { name: sp.name, value: sp.id };
    //   });
    //   choices.push({ name: "Built-In", value: "0", default: true });
    //   const newSettings = setSettingChoices(
    //     settings,
    //     "general",
    //     "defaultSystemPromptId",
    //     choices
    //   );

    //   dispatch({ field: "settings", value: newSettings });
    //   dispatch({ field: "systemPrompts", value: systemPrompts });
    // });
  }, [dispatch, database, user]);

  useEffect(() => {
    if (!database || !user) return;

    if (!settingsLoaded) {
      const newSavedSettings = getSavedSettings(user);

      dispatch({
        field: 'savedSettings',
        value: newSavedSettings
      });

      dispatch({
        field: 'settingsLoaded',
        value: true
      });
    }
  }, [dispatch, savedSettings, user, settingsLoaded, database]);

  useEffect(() => {
    if (savedSettings && settings) {
      const lightMode = getSavedSettingValue(savedSettings, 'personalization', 'theme', settings);

      dispatch({
        field: 'lightMode',
        value: lightMode
      });
    }
  }, [savedSettings, settings, dispatch]);

  if (user && database && conversations.length > 0) {
    return (
      <HomeContext.Provider
        value={{
          ...contextValue,
          handleNewConversation,
          handleCreateFolder,
          handleDeleteFolder,
          handleUpdateFolder,
          handleSelectConversation,
          handleUpdateConversation
        }}
      >
        {selectedConversation && (
          <div
            className={`relative flex-col text-sm overflow-y-hidden h-full max-h-full w-full
          text-black dark:text-white ${lightMode} m-0 p-0 overflow-hidden`}
          >
            <div className="absolute top-0 z-50 w-full sm:hidden">
              <Navbar
                selectedConversation={selectedConversation}
                onNewConversation={handleNewConversation}
              />
            </div>
            <div className="flex flex-shrink w-full h-full max-h-full pt-[50px] sm:pt-0 overflow-hidden overscroll-none">
              <PrimaryMenu />
              <ChatZone />
              <SecondaryMenu />
            </div>
          </div>
        )}
      </HomeContext.Provider>
    );
  } else {
    let text = '';

    if (!user) {
      text = 'Initializing Auth System...';
    } else if (!database) {
      text = 'Initializing Database...';
    } else if (conversations.length === 0) {
      text = 'Loading Conversations...';
    }

    return (
      <div
        className={`relative flex-col text-sm overflow-y-hidden h-full max-h-full w-full
          ${lightMode || 'dark'} m-0 p-0 overflow-hidden`}
      >
        <div
          className="flex flex-col items-center justify-center h-screen w-screen bg-[#ffffff]
        dark:bg-[#1f2428] dark:text-[#f0f0f0]"
        >
          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-row items-center justify-center">
              <p>AI Gateway</p>
            </div>
            <div className="flex flex-row items-center justify-center">
              <h2 className="text-xl font-bold text-primary-500">{text}</h2>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default Home;
