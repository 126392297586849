export interface AiModel {
  id: number;
  name: string;
  maxLength: number; // maximum length of a message
  tokenLimit: number;
  requestLimit: number;
  modelType: 'OpenAI' | 'Anthropic' | 'Google';
}

export interface PossibleAiModelsInterface {
  [name: string]: AiModel;
}

export const PossibleAiModels: PossibleAiModelsInterface = {
  'gpt-3.5-turbo': {
    id: 1,
    name: 'gpt-3.5-turbo',
    maxLength: 12000,
    tokenLimit: 4000,
    requestLimit: 3000,
    modelType: 'OpenAI'
  },
  'gpt-35-az': {
    id: 2,
    name: 'gpt-35-az',
    maxLength: 12000,
    tokenLimit: 4000,
    requestLimit: 3000,
    modelType: 'OpenAI'
  },
  'gpt-4': {
    id: 3,
    name: 'gpt-4',
    maxLength: 24000,
    tokenLimit: 8000,
    requestLimit: 6000,
    modelType: 'OpenAI'
  },
  'gpt-4-32k': {
    id: 4,
    name: 'gpt-4-32k',
    maxLength: 96000,
    tokenLimit: 32000,
    requestLimit: 30000,
    modelType: 'OpenAI'
  },
  'gpt-35-turbo': {
    id: 5,
    name: String(process.env.NEXT_PUBLIC_AZURE_DEPLOYMENT_ID),
    maxLength: 12000,
    tokenLimit: 4000,
    requestLimit: 3000,
    modelType: 'OpenAI'
  },
  'claude-instant-1': {
    id: 6,
    name: 'claude-instant-1',
    maxLength: 400000,
    tokenLimit: 100000,
    requestLimit: 98000,
    modelType: 'Anthropic'
  },
  'claude-2': {
    id: 7,
    name: 'claude-2',
    maxLength: 400000,
    tokenLimit: 100000,
    requestLimit: 98000,
    modelType: 'Anthropic'
  },
  bard: {
    id: 8,
    name: 'bard',
    maxLength: 12000,
    tokenLimit: 4096,
    requestLimit: 3000,
    modelType: 'Google'
  }
};
