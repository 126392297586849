import { getTimestampWithTimezoneOffset } from 'helpers/time/time';
import { MutableRefObject } from 'react';

import { User } from 'types/auth';
import { Conversation, Message } from 'types/chat';
import { Database } from 'types/database';

import { saveSelectedConversation } from 'utils/app/storage/local/selectedConversation';
import { storageGetMessages } from 'utils/app/storage/message';

export async function messageReceiver(
  user: User,
  database: Database,
  data: ReadableStream,
  controller: AbortController,
  conversation: Conversation,
  conversations: Conversation[],
  stopConversationRef: MutableRefObject<boolean>,
  homeDispatch: React.Dispatch<any>
) {
  const reader = data.getReader();
  const decoder = new TextDecoder();
  let done = false;
  let text = '';

  const responseMessage: Message = {
    role: 'assistant',
    isUserMessage: false,
    chatId: conversation.id!,
    content: '',
    timestamp: getTimestampWithTimezoneOffset()
  };
  conversation.messages.push(responseMessage);
  const length = conversation.messages.length;
  while (!done) {
    if (stopConversationRef.current === true) {
      controller.abort();
      done = true;
      break;
    }
    const { value, done: doneReading } = await reader.read();
    done = doneReading;
    const chunkValue = decoder.decode(value);

    text += chunkValue;

    conversation.messages[length - 1].content = text;

    homeDispatch({
      field: 'selectedConversation',
      value: conversation
    });
  }

  homeDispatch({ field: 'loading', value: false });
  homeDispatch({ field: 'messageIsStreaming', value: false });

  const { single, all } = await storageGetMessages(conversation, conversations);
  homeDispatch({
    field: 'selectedConversation',
    value: single
  });

  homeDispatch({ field: 'conversations', value: all });
  saveSelectedConversation(user, single);
}
