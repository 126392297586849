import { Dispatch, useCallback, useEffect, useState } from 'react';

import { ActionType } from 'hooks/useCreateReducer';

import { AiModel, PossibleAiModels } from 'types/ai-models';
import { User } from 'types/auth';
import { Conversation } from 'types/chat';
import { Database } from 'types/database';

import { HomeInitialState } from 'components/Home/home.state';

import { cleanConversationHistory, cleanSelectedConversation } from '../../utils/app/clean';
import { DEFAULT_MODEL, DEFAULT_TEMPERATURE } from '../../utils/app/const';

import { getTimestampWithTimezoneOffset } from '../../helpers/time/time';

import {
  getSelectedConversation,
  saveSelectedConversation
} from '../../utils/app/storage/local/selectedConversation';
import ChatApiService from 'api/chatApi/chatApiService';

export const useConversations = (
  homeDispatch: Dispatch<ActionType<HomeInitialState>>,
  database: Database | null,
  user: User | null,
  conversations: Conversation[],
  aiModels: AiModel[]
) => {
  const [conversationsLoaded, setConversationsLoaded] = useState(false);

  const fetchModels = useCallback(async () => {
    if (!conversationsLoaded) {
      if (database && user && aiModels.length > 0) {
        ChatApiService.getChatList()
          .then((conversationHistory) => {
            if (conversationHistory) {
              const parsedConversationHistory: Conversation[] = conversationHistory;
              const cleanedConversations = cleanConversationHistory(parsedConversationHistory);

              const selectedConversation = getSelectedConversation(user);
              if (selectedConversation) {
                const parsedSelectedConversation: Conversation = JSON.parse(selectedConversation);
                const cleanedSelectedConversation = cleanSelectedConversation(
                  parsedSelectedConversation,
                  aiModels
                );

                homeDispatch({
                  field: 'selectedConversation',
                  value: cleanedSelectedConversation
                });
              } else if (cleanedConversations.length > 0) {
                homeDispatch({
                  field: 'selectedConversation',
                  value: cleanedConversations[0]
                });
              }

              homeDispatch({
                field: 'conversations',
                value: cleanedConversations
              });
            }
          })
          .then(() => {
            setConversationsLoaded(true);
          });
      }
    }
  }, [conversationsLoaded, database, homeDispatch, user, aiModels]);

  useEffect(() => {
    fetchModels();
  }, [fetchModels]);

  const autogenerateConversation = useCallback(async () => {
    if (!database || !user || aiModels.length === 0) return;

    const model = aiModels[0];
    // const sectionId = model.vendor.toLowerCase();
    // const settingId = `${model.id}_default_system_prompt`;
    // const systemPromptId = getSavedSettingValue(
    //   savedSettings,
    //   sectionId,
    //   settingId,
    //   settings,
    // );

    // const systemPrompt = systemPrompts.find((p) => p.id === systemPromptId);

    const newConversation: Conversation = {
      name: 'New Conversation',
      messages: [],
      model: model,
      systemPrompt: null,
      temperature: DEFAULT_TEMPERATURE,
      folderId: null,
      timestamp: getTimestampWithTimezoneOffset()
    };
    const newChatId = await ChatApiService.createChat({
      name: newConversation.name
    });
    newConversation.id = newChatId;
    const updatedConversations = [newConversation];
    homeDispatch({ field: 'selectedConversation', value: newConversation });
    homeDispatch({ field: 'conversations', value: updatedConversations });

    saveSelectedConversation(user, newConversation);
  }, [database, homeDispatch, user, aiModels]);

  useEffect(() => {
    if (conversations.length === 0 && conversationsLoaded) {
      autogenerateConversation();
    }
  }, [conversations, autogenerateConversation, conversationsLoaded]);
};
