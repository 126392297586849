import ChatApiService from 'api/chatApi/chatApiService';
import { User } from 'types/auth';
import { Conversation } from 'types/chat';

export const storageUpdateConversation = async (
  user: User,
  updatedConversation: Conversation,
  allConversations: Conversation[],
  isLocal: boolean = false
) => {
  const updatedConversations = allConversations.map((c) => {
    if (c.id === updatedConversation.id) {
      return updatedConversation;
    }
    return c;
  });
  if (!isLocal)
    await ChatApiService.updateChat({
      name: updatedConversation.name,
      id: updatedConversation.id!
    });

  return {
    single: updatedConversation,
    all: updatedConversations
  };
};
