import { InfoTooltip } from 'components/Home/components/SecondaryMenu/components/Menu/components/Screens/ModelSettings/components/InfoTooltip';

interface Props {
  children: React.ReactNode;
  tip: string;
}

export const PrimaryLabel = ({ children, tip }: Props) => {
  return (
    <label className="pt-3 flex items-center text-left pl-1 text-black dark:text-white">
      {children}
      {tip && <InfoTooltip>{tip}</InfoTooltip>}
    </label>
  );
};
