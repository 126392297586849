import MessageApiService from 'api/messageApi/messageApiService';
import { Conversation, Message } from 'types/chat';

export const storageCreateMessage = async (
  selectedConversation: Conversation,
  newMessage: Message,
  allConversations: Conversation[]
) => {
  const messages = selectedConversation.messages;
  newMessage.id = await MessageApiService.createMessage({
    chatId: selectedConversation.id!,
    content: newMessage.content
  });

  const updatedMessages = [...messages, newMessage];
  const updatedConversation: Conversation = {
    ...selectedConversation,
    messages: updatedMessages
  };
  const updatedConversations = allConversations.map((c) => {
    if (c.id === updatedConversation.id) {
      return updatedConversation;
    }

    return c;
  });
  return { single: updatedConversation, all: updatedConversations };
};

export const storageGetMessages = async (
  selectedConversation: Conversation,
  allConversations: Conversation[]
) => {
  const messages = await MessageApiService.getMessageList(selectedConversation.id!);

  const updatedConversation: Conversation = {
    ...selectedConversation,
    messages: messages
  };
  const updatedConversations = allConversations.map((c) => {
    if (c.id === updatedConversation.id) {
      return updatedConversation;
    }

    return c;
  });
  return { single: updatedConversation, all: updatedConversations };
};

export const storageUpdateMessage = (
  selectedConversation: Conversation,
  updatedMessage: Message,
  allConversations: Conversation[]
) => {
  const messages = selectedConversation.messages;
  const updatedMessages = messages.map((m) => (m.id === updatedMessage.id ? updatedMessage : m));
  const updatedConversation: Conversation = {
    ...selectedConversation,
    messages: updatedMessages
  };
  const updatedConversations = allConversations.map((c) => {
    if (c.id === updatedConversation.id) {
      return updatedConversation;
    }

    return c;
  });

  return { single: updatedConversation, all: updatedConversations };
};
