import { getToken } from 'app/auth/msal';
import { PromptResponse } from './fetchStreamApiService.types';
import { BASE_URL } from 'api/base/constants';

const FetchStreamApi = {
  promptChat: async (
    chatId: number,
    temperature: number,
    aiModelId: number
  ): Promise<PromptResponse> => {
    const chatBody = { chatId, temperature, aiModelId };

    let body = JSON.stringify(chatBody);
    const token = await getToken();
    const controller = new AbortController();
    const response = await fetch(`${BASE_URL}messages/prompt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      signal: controller.signal,
      body
    });
    return { response: response, controller: controller };
  }
};

export default FetchStreamApi;
