declare global {
  interface Window {
    env: any;
  }
}

// change with your own variables
type EnvType = {
  REACT_APP_CLIENT_ID: string;
  REACT_APP_AUTHORITY: string;
  REACT_APP_API_SCOPE: string;
  REACT_APP_ENVIRONMENT?: 'Local' | 'Development';
};
export const env: EnvType = { ...process.env, ...window.env };
