import { Dispatch, useCallback, useEffect } from 'react';

import { ActionType } from 'hooks/useCreateReducer';

import { Database } from 'types/database';

import { HomeInitialState } from 'components/Home/home.state';

export const useDatabase = (
  homeDispatch: Dispatch<ActionType<HomeInitialState>>,
  database: Database | null
) => {
  const fetchDatabase = useCallback(async () => {
    if (!database) {
      homeDispatch({ field: 'database', value: { name: 'test' } });
    }
  }, [database, homeDispatch]);

  useEffect(() => {
    fetchDatabase();
  }, [fetchDatabase]);

  return;
};
