import FetchStreamApi from 'api/fetchStreamApi/fetchStreamApiService';
import toast from 'react-hot-toast';

import { Conversation } from 'types/chat';
import { SavedSetting } from 'types/settings';
import { SystemPrompt } from 'types/system-prompt';

export async function messageSender(
  builtInSystemPrompts: SystemPrompt[],
  updatedConversation: Conversation,
  selectedConversation: Conversation,
  savedSettings: SavedSetting[],
  homeDispatch: React.Dispatch<any>
) {
  let customPrompt = selectedConversation.systemPrompt;

  if (!selectedConversation.systemPrompt) {
    customPrompt = builtInSystemPrompts.filter(
      (prompt) => prompt.name === `${selectedConversation.model.modelType} Built-In`
    )[0];
  }

  const promptInjectedConversation = {
    ...updatedConversation,
    systemPrompt: customPrompt
  };

  try {
    const { response, controller } = await FetchStreamApi.promptChat(
      promptInjectedConversation.id!,
      promptInjectedConversation.temperature,
      promptInjectedConversation.model.id
    );

    if (!response.body) {
      homeDispatch({ field: 'loading', value: false });
      homeDispatch({ field: 'messageIsStreaming', value: false });
      return { data: null, controller: null };
    }

    homeDispatch({ field: 'loading', value: false });
    return { data: response.body, controller };
  } catch (error) {
    homeDispatch({ field: 'loading', value: false });
    homeDispatch({ field: 'messageIsStreaming', value: false });
    toast.error('Crashed');
    return { data: null, controller: null };
  }
}
