import { PublicClientApplication } from '@azure/msal-browser';
import { apiConfig, msalConfig } from './constants';

const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();

const signIn = async () => {
  try {
    let activeAccount = msalInstance.getActiveAccount();
    if (activeAccount) {
      return activeAccount;
    }
    await msalInstance.handleRedirectPromise();
    await msalInstance.loginPopup(apiConfig);
    if (!activeAccount && msalInstance.getAllAccounts().length > 0) {
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }
    return msalInstance.getActiveAccount()!;
  } catch (error) {
    console.error(error);
    throw Error('No active account! And could not log in');
  }
};

const signOut = async () => {
  try {
    await msalInstance.logoutPopup();
  } catch (error) {
    console.error(error);
    throw Error('No active account! And could not log out');
  }
};

const getToken = async () => {
  try {
    let account = msalInstance.getActiveAccount();
    if (account === null) {
      throw Error('No account set as active!');
    }
    const request = {
      account: account,
      ...apiConfig
    };
    const response = await msalInstance.acquireTokenSilent(request);
    return response.accessToken;
  } catch (error) {
    try {
      const tokenWithPopup = await msalInstance.acquireTokenPopup(apiConfig);
      return tokenWithPopup.accessToken;
    } catch (error) {
      console.error(error);
      throw Error('Something went wrong during token acquisition');
    }
  }
};
export { signIn, getToken, signOut };
