import { Dispatch, useCallback, useEffect } from 'react';

import { ActionType } from 'hooks/useCreateReducer';

import { User } from 'types/auth';

import { HomeInitialState } from 'components/Home/home.state';
import { signIn } from 'app/auth/msal';

export const useAuth = (
  homeDispatch: Dispatch<ActionType<HomeInitialState>>,
  user: User | null
) => {
  const fetchUser = useCallback(async () => {
    if (!user) {
      try {
        const userData = await signIn();
        homeDispatch({ field: 'user', value: userData });
      } catch (error) {
        console.log(error);
      }
    }
  }, [user, homeDispatch]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return;
};
