import { Conversation } from 'types/chat';
import { CreateChatRequest, UpdateChatRequest } from './chatApiService.types';
import axiosInstance from 'api/base/baseApi';

const baseUrl = '/Chats';

const ChatApiService = {
  getChatList: (): Promise<Conversation[]> =>
    axiosInstance.get(baseUrl).then((response) => response.data.chats),
  createChat: (body: CreateChatRequest): Promise<number> =>
    axiosInstance.post(baseUrl, body).then((response) => response.data.id),
  updateChat: (body: UpdateChatRequest): Promise<Conversation> =>
    axiosInstance.put(`${baseUrl}/${body.id}`, body),
  deleteChat: (chatId: number | undefined): Promise<void> =>
    axiosInstance.delete(`${baseUrl}/${chatId}`)
};

export default ChatApiService;
