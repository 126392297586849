import { Message } from 'types/chat';
import { CreateMessageRequest } from './messageApiService.types';
import axiosInstance from 'api/base/baseApi';

const baseUrl = '/Messages';

const MessageApiService = {
  getMessageList: (chatId: number): Promise<Message[]> =>
    axiosInstance.get(baseUrl + '?ChatId=' + chatId).then((response) => response.data.messages),
  deleteMessage: (messageId: number): Promise<void> =>
    axiosInstance.delete(baseUrl + `/${messageId}`),
  createMessage: (body: CreateMessageRequest): Promise<number> =>
    axiosInstance.post(baseUrl, body).then((response) => response.data.id),
  createAndPromptMessage: (body: CreateMessageRequest): Promise<number> =>
    axiosInstance.post(baseUrl + '/prompt/new', body).then((response) => response.data.id)
};

export default MessageApiService;
